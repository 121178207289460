.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

  background-color: #efefef;
  flex-direction: column;
}

.calculator-wrapper {
  width: 350px;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0px;
}

.row {
  display: flex;
  width: 100%;
}
