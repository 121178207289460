.input {
  height: 2em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  font-size: 1.4em;
  background-color: #fff;
  color: #000;
  padding: 0.5em;
  border: 1px solid #efefef;
  margin-bottom: 6px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin: 20px 0px 20px 0px;
}
